import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PortableText from 'react-portable-text';
import { Link } from 'gatsby';

import {
	Grid,
	Typography,
	Card,
	CardContent,
	makeStyles,
  Button,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.workwaveBlue,
		padding: '0px',
		borderRadius: '20px',
		minHeight: '539px',
		boxShadow: '0 10px 40px 0px rgba(0,0,0,0.15)',
		position: 'relative',
		[theme.breakpoints.down('md')]: {
			//width: '500px',
			minHeight: '635px',
		},
		[theme.breakpoints.down('sm')]: {
			//width: '350px',
      minHeight: '200px'
		},
		transition: 'all transform 0.25s',
		'&:hover': {
			boxShadow: '0 30px 80px 0px rgba(0,0,0,0.15)',
		},
	},
	title: {
		fontSize: '1.25rem',
		fontWeight: 600,
		textAlign: 'left',
		color: '#183B56',
		marginLeft: '.5rem',
		lineHeight: 1.3,
		[theme.breakpoints.down('xs')]: {
			marginLeft: '1rem',
		},
	},
	header: {
		fontSize: '1.625rem',
		fontWeight: 700,
	},
	pos: {
		textAlign: 'left',
		lineHeight: 1.4,
    fontSize: '1.25rem',
    fontFamily: ['Roboto', 'sans-serif'].join(),
	},
	discount: {
		borderRadius: '10px 10px 0px 0px',
		minHeight: '60px',
		width: '250px',
	},
	cardBody: {
		marginTop: '16px',
		minHeight: '110px',
	},
	button: {
		//background: '#2A7ABC',
		//color: '#fff',
		//padding: '1.6rem 2rem',
		opacity: 1,
		fontSize: '1.125rem',
		fontWeight: 600,
		transition: 'all 0.35s ease !important',
		borderRadius: '8px',
		textTransform: 'uppercase',
		'&:hover': {
			background: '#2A7ABC',
			opacity: '0.85 !important',
			'& $arrow': { transform: 'translateX(10px)' },
		},
		[theme.breakpoints.down('md')]: {
			margin: '1rem 0 1rem 1rem',
			padding: '16px 32px',
			borderRadius: '8px',
		},
	},
}));

export const HighlightCard = ({ card, whyPestpac, index }) => {
	const classes = useStyles();
	const { title, header, _rawContent, icon, accentColor, ctaText, ctaLink } = card;

  return (
		<Grid
			item
			xs={12}
			md={6}
			container
			direction='column'
			justifyContent='center'
			alignItems='center'
			style={{ paddingBottom: '25px' }}>
				<Card
					className={classes.root}
					style={{ zIndex: 100, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
					<Grid
						container
						alignItems='center'
						justifyContent='flex-start'
						style={{
							top: 0,
							borderTop: `16px solid ${card.accentColor.hexValue ?? '#002D5C'}`,
							background: card.accentColor.hexValue,
						}}></Grid>
					<CardContent
						style={{
							minHeight: '110px',
							display: 'flex',
							flexDirection: 'column',
							padding: '24px',
							alignItems: 'space-evenly',
							justifyContent: 'space-between',
              height: '100%',
						}}>
						<Grid
							item
							container
							direction='row'
							justifyContent='flex-start'
							alignItems='center'
              >
							<FontAwesomeIcon
								icon={[whyPestpac ? 'fad' : 'fas', icon]}
								style={{
									color: accentColor.hexValue ?? '#002D5C',
									height: '60px',
									width: '60px',
									padding: '10px',
									textAlign: 'center',
									border: whyPestpac && `1px solid #E5EAF4`,
									borderRadius: whyPestpac && '8px',
									background: whyPestpac && '#E5EAF4',
								}}
							/>
							<Typography
								variant='h4'
								color='primary'
								className={classes.header}
								style={{ marginLeft: whyPestpac && '1rem' }}>
								{header}
							</Typography>
						{/* </Grid>
						<Grid
							container
							item
							direction='row'
							justifyContent='flex-start'
							alignItems='flex-start'
							className={classes.cardBody}> */}
							<PortableText
								content={_rawContent}
								serializers={{
									normal: ({ children }) => (
										<Typography variant='body1' className={classes.pos} style={{ paddingTop: '1rem' }}>
											{children}
										</Typography>
									),
									li: ({ children }) => (
										<li>
											<Typography variant='body1' className={classes.pos} style={{ lineHeight: 1.3, paddingBottom: '1rem' }}>
												{children}
											</Typography>
										</li>
									),
								}}
							/>
						</Grid>
            {ctaLink && (
            <Grid
              container
              item
              justifyContent='center'
              alignItems='center'>
              <Link to={`${ctaLink}`} style={{ textDecoration: 'none' }}>
                <Button
                  variant='contained'
                  size='large'
                  className={classes.button}
                  style={{
                    background: accentColor.hexValue ?? '#002D5C',
                    color: 'white',
                    marginTop: '1rem',
                    padding: '16px 32px',
                  }}>
                  {ctaText}
                </Button>
              </Link>
            </Grid>
            )}
					</CardContent>
				</Card>
		</Grid>
	);
};
