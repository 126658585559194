import React from 'react';
import PortableText from 'react-portable-text';

import { Container, Grid, Typography, makeStyles } from '@material-ui/core';
import { HighlightCard } from './HighlightCard';
import { SoftwareFeatures } from '../General/SoftwareFeatures';
import { WaveDownSVG } from '../WaveSVGs/WaveDownSVG';
import { WaveUpSVG } from '../WaveSVGs/WaveUpSVG';

const useStyles = makeStyles((theme) => ({
	introHeader: {
		lineHeight: 1.0,
		[theme.breakpoints.down('xs')]: {
			lineHeight: 1.2,
		},
	},
	paragraph: {
		lineHeight: '32px',
		marginTop: '1rem',
		fontWeight: 500,
    fontSize: '1.25rem',
    fontFamily: ['Roboto', 'sans-serif'].join(),
	},
	tableContainer: {
		background: '#F5F9FF',
		padding: '14rem 0',
		[theme.breakpoints.down('sm')]: {
			padding: '10rem 0',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '4rem 0 8rem 0',
		},
	},
}));

export const WhyPestpacBody = ({
	intro,
	highlightCards,
	softwareFeatureListHeader,
	softwareFeatures,
}) => {
	const classes = useStyles();
	return (
		<>
			<Container style={{ paddingTop: '4rem', position: 'relative', zIndex: '6' }}>
				<Grid 
          container
          xs={12}
          lg={10}>
					<PortableText
						content={intro}
						serializers={{
							h2: ({ children }) => (
								<Typography
									variant='h2'
									color='primary'
									className={classes.introHeader}>
									{children}
								</Typography>
							),
							normal: ({ children }) => (
								<Typography variant='body1' className={classes.paragraph}>
									{children}
								</Typography>
							),
						}}
					/>
				</Grid>
				<Grid container style={{ paddingTop: '4rem' }} spacing={2}>
					{highlightCards.map((card, index) => (
						<HighlightCard card={card} key={index} whyPestpac index={index} />
					))}
				</Grid>
				<a id='feature'></a>
			</Container>
			<WaveDownSVG fill='#FFF' shadow />
			<div className={classes.tableContainer}>
				<Container>
					<SoftwareFeatures
						header={softwareFeatureListHeader}
						// subheader={supportedProductsSubheader}
						softwareFeatures={softwareFeatures}
						whyPestpac
						// accentColor={accentColor[0].hexValue}
					/>
				</Container>
			</div>
			<WaveUpSVG fill='#FFF' shadow />
		</>
	);
};
